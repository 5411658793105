<template>
  <crud-form
    v-if="form.user"
    :schema="schema"
    :model="form"
    :path="'users-alerts'"
    :form-title="$t('addUserToBlackList')"
    :redirect-route="'prev'"
  />
</template>

<script>
  import CrudForm from "../../components/CrudForm";
  import { form } from './forms/user-alert'
  import { schema } from './schema/user-alert'
  import {APIService} from "../../services/api";

  export default {
    name: 'AddUserAlert',
    components: {
      CrudForm
    },
    props: ['id'],
    data: () => {
      return {
        form,
        schema,
      }
    },
    async beforeMount() {
      try {
        const user = await APIService.get(`users/${this.id}`);
        this.form.user = user;
      } catch (e) {
        console.error(e);
      }
    }
  }
</script>
