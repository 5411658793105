import { validators } from "vue-form-generator";
import i18n from "@/plugins/i18n";

export const schema = {
  fields: [
    {
      type: "textArea",
      label: "userAlertNote",
      model: "note",
      required: false,
      placeholder: "userAlertNote",
      max: 2048,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("userAlertNoteMustBeLess{1}"),
        }),
      ]
    },
  ]
};
